.App {
    height: 100%;
    width: 100%;

    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
}

.navbar {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  border-color: transparent;
}

button.navbar-toggler{
  background-color: #b7b7b7;
  margin-top:10px;
}

nav.navbar.navbar-default div.container div.navbar-header a.navbar-brand {
    font-size: 25pt;
    color: rgba(208,199,199,.8);
    font-family: Open Sans,sans-serif;
}

nav.navbar.navbar-default div.container div.navbar-collapse.collapse ul.nav.navbar-nav.navbar-right li a {
    font-size: 25pt;
    color: hsla(0,0%,100%,.8);
    font-family: Open Sans,sans-serif;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;

  /* Scroll Snap */
  scroll-snap-align: center;
}

section h1{
  font-size: 4rem;
}

section p{
  font-size: 1.5rem;
}

section#main {
  background: url(./smallbackground.jpg) no-repeat center center/cover;;
}

section#contactus {
    background: url(./fakegrass.jpg) no-repeat center center/cover;;
}

html body div#root div.App.flexcontainer nav.navbar.navbar-default div.container div.navbar-collapse.collapse ul.nav.navbar-nav.navbar-right li.active {
    background-color: rgba(0,0,0,1);
}

nav.navbar.navbar-default div.container div.navbar-collapse.collapse ul.nav.navbar-nav.navbar-right li.active a {
    font-size: 25pt;
    color: rgba(0,0,0,1);
    font-family: Open Sans,sans-serif;
}

@media only screen and (max-width: 600px) {
  .scrolling-box {
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: mandatory;
    scroll-snap-align: start;

    scroll-snap-destination: 0% 100%;
    scroll-snap-points-x: repeat(100%);
  }

  .navbar {
    position: fixed;
    display: flex;
    width: 100%;
    background-color: rgba(0,0,0,0.6);
    border-color: transparent;
  }

  form{
    width: 100vw;
    background-color: transparent;
    color: rgba(0,0,0,0.8);
  }

  nav.navbar.navbar-default div.container div.navbar-collapse.collapse ul.nav.navbar-nav.navbar-right li a {
    font-size: 2rem;
    font-family: Open Sans,sans-serif;
  }

  nav.navbar.navbar-default div.container div.navbar-collapse.collapse ul.nav.navbar-nav.navbar-right li.active a {
    font-size: 2rem;
    font-family: Open Sans,sans-serif;
  }
}

  @media only screen and (max-width: 350px) {
    nav.navbar.navbar-default div.container div.navbar-header a.navbar-brand {
        font-size: 20pt;
        color: rgba(208,199,199,.8);
        font-family: Open Sans,sans-serif;
    }
}
