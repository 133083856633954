
.Login {
  padding: 60px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.Login form a{
  color: white;
  margin-bottom: 5px;
}
