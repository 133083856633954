* {
  margin: 0;
  padding: 0;
}

html,body {
  height:100vh;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
}

#root{
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

form {
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
}
