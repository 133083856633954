.Home .lander {
  padding: 80px 0;
  text-align: center;

}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 50pt;
  color: rgba(255, 255, 255, 0.6);
  top: 40%;
  left: 15%;
}

@media only screen and (max-width: 600px) {
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-size: 60pt;
    color: rgba(255, 255, 255, 0.6);
    top: 30%;
    left: 10%;
  }
}

.Home .lander p {
  color: #999;
}
